// All imports
import { gsap } from "gsap/all";
import barba from "@barba/core";
import campaignCookie from "./js-comps/campaign-cookie-for-barba";

//countdown-timer logic for BSN Page
const countdownBsn = (timer) => {
  const dateStart = timer.getAttribute("data-date-end");
  // Set the date we're counting down to
  var countDownDate = new Date(dateStart).getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //if check to stop countdown logic from executing on different views and Timers for BSN
    if (document.getElementById("countdown-timer-BSN")) {
      // Display the result in the element with correct IDs
      document.getElementById("countdown-timer-days").textContent = days + ":D";
      document.getElementById("countdown-timer-hours").textContent =
        hours + ":H";
      document.getElementById("countdown-timer-minutes").textContent =
        minutes + ":M";
      document.getElementById("countdown-timer-seconds").textContent =
        seconds + ":S";
      +minutes + "m " + seconds + "s ";
    }

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("countdown-timer-days").textContent = "0d ";
      document.getElementById("countdown-timer-hours").textContent = "0h ";
      document.getElementById("countdown-timer-minutes").textContent = "0m ";
      document.getElementById("countdown-timer-seconds").textContent = "0s ";
    }
  }, 1000);
};

//countdown-timer logic for PTA page
const countdownPta = (timer) => {
  const dateStart = timer.getAttribute("data-date-end");
  // Set the date we're counting down to
  var countDownDate = new Date(dateStart).getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //if check to stop countdown logic from executing on different views and Timers for BSN
    if (document.getElementById("countdown-timer-PTA")) {
      // Display the result in the element with correct IDs
      document.getElementById("countdown-timer-days").textContent = days + ":D";
      document.getElementById("countdown-timer-hours").textContent =
        hours + ":H";
      document.getElementById("countdown-timer-minutes").textContent =
        minutes + ":M";
      document.getElementById("countdown-timer-seconds").textContent =
        seconds + ":S";
      +minutes + "m " + seconds + "s ";
    }

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("countdown-timer-days").textContent = "0d ";
      document.getElementById("countdown-timer-hours").textContent = "0h ";
      document.getElementById("countdown-timer-minutes").textContent = "0m ";
      document.getElementById("countdown-timer-seconds").textContent = "0s ";
    }
  }, 1000);
};

//countdown-timer logic for Dental Hygiene
const countdownDental = (timer) => {
  const dateStart = timer.getAttribute("data-date-end");
  // Set the date we're counting down to
  var countDownDate = new Date(dateStart).getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //if check to stop countdown logic from executing on different views and Timers for BSN
    if (document.getElementById("countdown-timer-DH")) {
      // Display the result in the element with correct IDs
      document.getElementById("countdown-timer-days").textContent = days + ":D";
      document.getElementById("countdown-timer-hours").textContent =
        hours + ":H";
      document.getElementById("countdown-timer-minutes").textContent =
        minutes + ":M";
      document.getElementById("countdown-timer-seconds").textContent =
        seconds + ":S";
      +minutes + "m " + seconds + "s ";
    }

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("countdown-timer-days").textContent = "0d ";
      document.getElementById("countdown-timer-hours").textContent = "0h ";
      document.getElementById("countdown-timer-minutes").textContent = "0m ";
      document.getElementById("countdown-timer-seconds").textContent = "0s ";
    }
  }, 1000);
};

///////////////////////////////////////////
// Start of Logic used for Barba.js
///////////////////////////////////////////

// Animation functions used between transitions in barba.js

const programSection = document.querySelector(".program-content");
const programIndexDirections = document.querySelector(
  ".program-index-directions"
);

const animationEnter = (container) => {
  programSection.scrollTop = 0;

  let tl = new gsap.timeline();

  tl.from(".stagger", {
    opacity: 0,
    x: 800,
    duration: 0.5,
    stagger: 0.1,
    ease: "power1",
  })
    .from(
      ".scale",
      { opacity: 0, scale: 0, duration: 0.5, stagger: 0.25 },
      ">-0.5"
    )
    .from(".rest-of", { opacity: 0, duration: 1 }, ">-0.5");

  return tl;
};

const animationLeave = (container) => {
  let tl = new gsap.timeline();

  if (window.innerWidth <= 1370) {
    tl
      // .to('.')
      .to(".scale", { opacity: 0, scale: 0, duration: 0.5, stagger: 0.25 })
      .to(".rest-of", { opacity: 0, duration: 1 }, ">-0.5")
      .to(
        ".stagger",
        { opacity: 0, x: 800, duration: 0.5, stagger: 0.1, ease: "power1" },
        ">-0.5"
      );

    return tl;
  }

  tl.to(".scale", { opacity: 0, scale: 0, duration: 0.5, stagger: 0.25 })
    .to(".rest-of", { opacity: 0, duration: 1 }, ">-0.5")
    .to(
      ".stagger",
      { opacity: 0, x: 800, duration: 0.5, stagger: 0.1, ease: "power1" },
      ">-0.5"
    );

  return tl;
};

const programsIndexReveal = () => {
  let tl = new gsap.timeline();

  if (window.innerWidth <= 1370) {
    programIndexDirections.textContent =
      "";

    tl.from(".stagger", {
      opacity: 0,
      y: 1000,
      duration: 0.5,
      stagger: 0.1,
      ease: "power1",
    })
      .from(
        ".arrow-img",
        { opacity: 0, y: 1000, duration: 0.5, stagger: 0.25 },
        ">-0.5"
      )
      .fromTo(
        ".arrow-img",
        { x: 0 },
        { y: -100, duration: 1, yoyo: true, repeat: -1, ease: "sine.inOut" }
      );

    return tl;
  }

  tl.from(".stagger", {
    opacity: 0,
    x: 1000,
    duration: 0.5,
    stagger: 0.1,
    ease: "power1",
  })
    .from(
      ".arrow-img",
      { opacity: 0, x: 1000, duration: 0.5, stagger: 0.25 },
      ">-0.5"
    )
    .from(".keaton-doll", {
      opacity: 0,
      duration: 0.5,
      ease: "power1",
    })
    .fromTo(
      ".arrow-img",
      { x: 0 },
      { x: -100, duration: 1, yoyo: true, repeat: -1, ease: "sine.inOut" },
      ">-0.5"
    );

  return tl;
};

const programsIndexVanish = () => {
  let tl = new gsap.timeline();

  if (window.innerWidth <= 1370) {
    tl.to(".stagger", {
      opacity: 0,
      y: 1000,
      duration: 0.5,
      stagger: 0.1,
      ease: "power1",
    })
      .to(
        ".arrow-img",
        { opacity: 0, y: 1000, duration: 0.5, stagger: 0.25 },
        ">-0.5"
      )
      .to(
        ".arrow-img",
        { x: 0 },
        { y: -100, duration: 1, yoyo: true, repeat: -1, ease: "sine.inOut" }
      );

    return tl;
  }

  tl.to(".stagger", {
    opacity: 0,
    x: 1000,
    duration: 0.5,
    stagger: 0.1,
    ease: "power1",
  })
    .to(".arrow-img", { x: 100, duration: 0.25, ease: "power2" }, ">-0.25")
    .to(".arrow-img", { opacity: 0, x: -1000, duration: 1, ease: "power2" })
    .to(
      ".keaton-doll",
      {
        x: 1000,
        opacity: 0,
        duration: 1,
      },
      ">-1"
    );

  return tl;
};

///////////////////////////////////////////
// Barba.js functionality
///////////////////////////////////////////

barba.init({
  views: [
    {
      namespace: "program-content",
      afterEnter() {
        campaignCookie();
      }
    },
    {
      namespace: "program-content-w/bsn-timer",
      beforeEnter(data) {
        countdownBsn(data.next.container.querySelector("#countdown-timer-BSN"));
      },
      afterEnter() {
        campaignCookie();
      },
    },
    {
      namespace: "program-content-w/dental-timer",
      beforeEnter(data) {
        countdownDental(
          data.next.container.querySelector("#countdown-timer-DH")
        );
      },
      afterEnter() {
        campaignCookie();
      },
    },
    {
      namespace: "program-content-w/pta-timer",
      beforeEnter(data) {
        countdownPta(data.next.container.querySelector("#countdown-timer-PTA"));
      },
      afterEnter() {
        campaignCookie();
      },
    },
    {
      namespace: "program-content-w/collapsible",
      beforeEnter(data) {
        const collapsibles = data.next.container.querySelectorAll(
          ".program-collapsible"
        );

        const collapsibleHeadings = data.next.container.querySelectorAll(
          ".program-collapsible__heading"
        );

        collapsibleHeadings.forEach((collapsibleHeading, i) => {
          collapsibleHeading.addEventListener("click", () => {
            collapsibles[i].classList.toggle("open");
          });
        });
      },
      afterEnter() {
        campaignCookie();
      },
    },
    {
      namespace: "program-content-w/btn-switcher",
      beforeEnter(data) {
        const containers = data.next.container.querySelectorAll(
          ".online-course-container"
        );

        const btnSwitches = data.next.container.querySelectorAll(".btn-switch");

        btnSwitches.forEach((btn, i) => {
          btn.addEventListener("click", () => {
            btnSwitches.forEach((b) => {
              b.classList.remove("active");
            });

            containers.forEach((c) => {
              c.classList.add("hide");
            });

            btn.classList.add("active");
            containers[i].classList.remove("hide");
          });
        });
      },
      afterEnter() {
        campaignCookie();
      },
    },
    {
      namespace: "program-content-w/btn-switcher-and-collapsible",
      beforeEnter(data) {
        const containers = data.next.container.querySelectorAll(
          ".online-course-container"
        );

        const btnSwitches = data.next.container.querySelectorAll(".btn-switch");

        btnSwitches.forEach((btn, i) => {
          btn.addEventListener("click", () => {
            btnSwitches.forEach((b) => {
              b.classList.remove("active");
            });

            containers.forEach((c) => {
              c.classList.add("hide");
            });

            btn.classList.add("active");
            containers[i].classList.remove("hide");
          });
        });
        const collapsibles = data.next.container.querySelectorAll(
          ".program-collapsible"
        );

        const collapsibleHeadings = data.next.container.querySelectorAll(
          ".program-collapsible__heading"
        );

        collapsibleHeadings.forEach((collapsibleHeading, i) => {
          collapsibleHeading.addEventListener("click", () => {
            collapsibles[i].classList.toggle("open");
          });
        });
      },
      afterEnter() {
        campaignCookie();
      },
    },
  ],
  // These are the transitions used between pages
  // Name given is for organization, to: and from: correlate to namespace
  transitions: [
    {
      name: "program-index-to",
      to: {
        namespace: ["program-index"],
      },
      once(data) {
        programsIndexReveal(data.next.container);
      },
      leave: (data) => animationLeave(data.current.container),
      enter: (data) => {
        programsIndexReveal(data.next.container);
      },
    },
    {
      name: "program-index-from",
      from: {
        namespace: ["program-index"],
      },
      once(data) {
        animationEnter(data.next.container);
      },
      leave: (data) => programsIndexVanish(data.current.container),
      enter(data) {
        animationEnter(data.next.container);
      },
    },
    {
      name: "program-transition",
      once(data) {
        animationEnter(data.next.container);
      },
      leave: (data) => animationLeave(data.current.container),
      enter(data) {
        animationEnter(data.next.container);
      },
    },
  ],
});
