function campaignCookie() {
  //check url for campaign source
  const campaign = new URLSearchParams(window.location.search).get('campaign_source');
  
  if (campaign) { //continue if campaign source present in url
    //if campaign source matches set lead source
    try {
      // if (campaign == 'youtube') {
      //   document.getElementById('lead_source').value = 'RFI - YouTube Ad'
      // }
      if (campaign.includes('B2') || campaign.includes('b2')) {
        document.getElementById('lead_source').value = `RFI-${campaign}`
      }
    }
    catch {
      console.log("No RFI Form Found on Page");
    }

    //create campaign source cookie
    const d  = new Date();
    d.setTime(d.getTime() + (3 * 24*60*60*1000)); //cookie expires in 3 days
    let expires = "expires=" + d.toUTCString();
    document.cookie = `campaign_source=${campaign};${expires};path=/`;
  }
  else { //if no campaign source in URL, check for campaign source cookie
    let cookie = '';
    let name = "campaign_source=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        cookie = c.substring(name.length, c.length);
      }
    }

    //if campaign source cookie exists, set lead source
    try {
      // if (cookie == 'youtube') {
      //   document.getElementById('lead_source').value = 'RFI - YouTube Ad'
      // }
      if (cookie.includes('B2') || cookie.includes('b2')) {
        document.getElementById('lead_source').value = `RFI-${cookie}`
      }
    }
    catch {
      console.log("No RFI Form Found on Page");
    }
  }
}

export default campaignCookie;